import React, { useEffect } from "react";
import {
  SettingsOutlined,

  HomeOutlined,
  MenuOpenOutlined,

  DashboardOutlined,

  CollectionsOutlined,

  WidgetsOutlined,
  FlagOutlined,

  MonetizationOn,
  DirectionsRunOutlined,

  AccountTreeOutlined,

  SettingsSuggestOutlined,
  InsightsOutlined,

  LanOutlined,
  RouteOutlined,
  AppRegistrationOutlined,
  MenuBookOutlined,
  AdminPanelSettingsOutlined,

  PersonOutlined,
  AttachMoneyOutlined,

  CalendarMonthOutlined,
  CalculateOutlined,
  CloudOutlined,
  HelpOutlineOutlined,
  RadarOutlined,
  PolicyOutlined,
  InfoOutlined,
  AddCircleOutlineOutlined,
  PatternOutlined,
  NextPlanOutlined,
  AttachFileOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogues } from "slices/catalogue";



const GetMenuItems = (user, swagger_path) => {

  const dispatch = useDispatch();

  const catalogues = useSelector((state) => state.catalogue.catalogue.data.list);

  const catalogusPricing = catalogues.filter((catalogue) => catalogue.type === "Pricing" && catalogue.active === true)
  const catalogusHelp = catalogues.filter((catalogue) => catalogue.type === "OpsBlox Help" && catalogue.active === true)
  const catalogusSOP = catalogues.filter((catalogue) => catalogue.type === "Standard Operating Procedure" && catalogue.active === true)
  const catalogusFinopsPolicies = catalogues.filter((catalogue) => catalogue.type === "FinOps Policies" && catalogue.active === true)
  const catalogusPattens = catalogues.filter((catalogue) => catalogue.type === "Pattern Library" && catalogue.active === true)
  const catalogusMetholdology = catalogues.filter((catalogue) => catalogue.type === "FinOps Metholdology" && catalogue.active === true)

  //console.log("Catalogues for menu: ", catalogues)

  useEffect(() => {
    //search.id = rowId;
    //debugger;

    const search ={
      filter: "[active]=true",
      sort: "sequence",
    }
      dispatch(getCatalogues({ search }));
  }, []);

  let navAreas = [
    {
      text: "Home",
      icon: <HomeOutlined />,
      mainItems: [
        {
          icon: <HomeOutlined />,
          text: "Home",
          external: false,
          header: true,
        },
        {
          text: "Home",
          link: "",
          external: false,
          header: false,
        },
        {
          text: "Opportunties",
          icon: <CalculateOutlined />,
          external: false,
          header:true,
          
        },
        {
          text: "Create an Opportunity",
          link: "opportunity",
          external: false,
          homePage:true,
          icon: <AddCircleOutlineOutlined />,
        },
        {
          text: "All Opportunties",
          link: "opportunity",
          external: false,
          homePage:true,
          icon: <CalculateOutlined />,
        },
        {
          text: "Just My Opportunties",
          link: `opportunity?filter=[owner]=${user?.username}`,
          external: false,
        },
        {
          text: "Opportunties being prevented",
          link: `opportunity?filter=[stage]=To Be Prevented`,
          external: false,
        },
        {
          text: "Diary",
          icon: <CalendarMonthOutlined />,
          external: false,
          header: true,
        },
        {
          text: "My Tasks",
          link: "diary",
          external: false,
          homePage:true,
          icon: <CalendarMonthOutlined />,
        },
        {
          text: "My Overdue Tasks",
          link: "diary",
          external: false,
        },
        {
          text: "My Priority Tasks",
          link: "diary",
          external: false,
        },
        {
          text: "All Tasks",
          link: "diary",
          external: false,
        },
        {
          text: "About",
          icon: <InfoOutlined />,
          external: false,
          header: true,
        },
        {
          text: "About OpsBlox",
          link: "about",
          external: false,
        },
        {
          text: "Privacy Policy",
          link: "privacy",
          external: false,
        },
        {
          text: "Terms of Service",
          link: "terms",
          external: false,
        },
      ],
    },

    {
      text: "Dashboards",
      icon: <DashboardOutlined />,
      mainItems: [
        {
          text: "Dashboards",
          icon: <DashboardOutlined />,
          link: "dashboard",
          external: false,
          header: true,
        },
        {
          text: "DashBoard List",
          link: "dashboard",
          external: false,
        },
        
        {
          text: "KPIs",
          icon: <InsightsOutlined />,
          link: "",
          external: false,
          header: true,
        },
        {
          icon: <DashboardOutlined />,
          text: "Overview Dashboard",
          link: "dashboard/Overview",
          external: false,
          homePage: true,
        },
        {
          text: "Azure Discounts",
          link: "dashboard/AzureDiscounts",
          external: false,
        },
        {
          text: "Lost Savings",
          link: "dashboard/LostSavings",
          external: false,
          icon: <InsightsOutlined />,
          homePage: true,
        },

        // {
        //   text: "Summary",
        //   link: "dashboard/kpi?tab=summary",
        //   external: false,
        // },
        
        // {
        //   text: "Cost Effectiveness",
        //   link: "dashboard/kpi?tab=cost-effectiveness",
        //   external: false,
        // },

        {
          text: "Spend",
          icon: <AttachMoneyOutlined />,
          link: "",
          external: false,
          header: true,
        },
        {
          text: "Consumption",
          link: "dashboard/AzureConsumption",
          external: false,
        },

        // {
        //   text: "Progress",
        //   icon: <TrendingUpOutlined />,
        //   link: null,
        //   external: false,
        //   header: true,
        
      ],
      
      customItems: [

        {
          text: "User Dashboards",
          icon: <DashboardOutlined />,
          link: null,
          external: false,
          header: true,
        },
        {
          text: "Kierans test 1",
          link: "dashboard",
          external: false,
        },

      ],
      
    },

        

    {
      text: "Networks",
      icon: <LanOutlined />,
      mainItems: [
        {
          text: "My Organization",
          icon: <CollectionsOutlined />,
          external: false,
          header: true,
        },

        {
          text: "Organization",
          link: `organization/${user?.organization}`,
          external: false,
        },
        {
          text: "Orchestrators",
          link: "orchestrator",
          external: false,
        },
        {
          text: "Customer Network",
          link: "customer_network",
          external: false,
          icon: <CollectionsOutlined />,
          homePage:true,
        },

        {
          text: "Cloud",
          icon: <CloudOutlined />,
          link: null,
          external: false,
          header: true,
        },

        {
          text: "Cloud Org Structure ",
          link: "cloudorgstructure",
          external: false,
          icon: <CloudOutlined />,
          homePage:true,
        },
      ],
    },

    {
      text: "Mappings",
      icon: <RouteOutlined />,
      mainItems: [
        {
          text: "State Sets",
          link: "state",
          external: false,
        },
      ],
    },

    {
      text: "Blox",
      icon: <WidgetsOutlined />,
      mainItems: [],
    },

    {
      text: "Registeres",
      icon: <AppRegistrationOutlined />,
      mainItems:[

        {
          text: "Opportunties",
          icon: <CalculateOutlined />,
          external: false,
          header:true,
          
        },
        {
          text: "Create an Opportunity",
          link: "opportunity",
          external: false,
          homePage:true,
          icon: <AddCircleOutlineOutlined />,
        },
        {
          text: "All Opportunties",
          link: "opportunity",
          external: false,
          homePage:true,
          icon: <CalculateOutlined />,
        },
        {
          text: "Just My Opportunties",
          link: `opportunity?filter=[owner]=${user?.username}`,
          external: false,
        },
        {
          text: "Opportunties being prevented",
          link: `opportunity?filter=[stage]=To Be Prevented`,
          external: false,
        },
        
      ]
    },

    {
      text: "Catalogues",
      icon: <MenuBookOutlined />,
      mainItems: [
        // Other items
        ...(catalogusPricing.length > 0
          ? [
              {
                text: "Pricing",
                icon: <MonetizationOn />,
                external: false,
                header: true,
              },
              ...catalogusPricing.map((catalogue) => ({
                text: catalogue.name,
                link: catalogue.isService ? catalogue.url : `catalogue/${catalogue.id}`,
                external: catalogue.isExternal,
              })),
            ]
          : []),

          ...(catalogusPattens.length > 0
            ? [
                {
                  text: "Pattern Library",
                  icon: <PatternOutlined  />,
                  external: false,
                  header: true,
                },
                ...catalogusPattens.map((catalogue) => ({
                  text: catalogue.name,
                  link: catalogue.isService ? catalogue.url : `catalogue/${catalogue.id}`,
                  external: catalogue.isExternal,
                })),
              ]
            : []),

          ...(catalogusSOP.length > 0
            ? [
                {
                  text: "Standard Operating Procedure",
                  icon: <AccountTreeOutlined />,
                  external: false,
                  header: true,
                },
                ...catalogusSOP.map((catalogue) => ({
                  text: catalogue.name,
                  link: catalogue.isService ? catalogue.url : `catalogue/${catalogue.id}`,
                  external: catalogue.isExternal,
                })),
              ]
            : []),

            ...(catalogusFinopsPolicies.length > 0
              ? [
                  {
                    text: "FinOps Policies",
                    icon: <PolicyOutlined />,
                    external: false,
                    header: true,
                  },
                  ...catalogusFinopsPolicies.map((catalogue) => ({
                    text: catalogue.name,
                    link: catalogue.isService ? catalogue.url : `catalogue/${catalogue.id}`,
                    external: catalogue.isExternal,
                  })),
                ]
              : []),

              ...(catalogusMetholdology.length > 0
                ? [
                    {
                      text: "FinOps Metholdology",
                      icon: <NextPlanOutlined  />,
                      external: false,
                      header: true,
                    },
                    ...catalogusMetholdology.map((catalogue) => ({
                      text: catalogue.name,
                      link: catalogue.isService ? catalogue.url : `catalogue/${catalogue.id}`,
                      external: catalogue.isExternal,
                    })),
                  ]
                : []),

            ...(catalogusHelp.length > 0
              ? [
                  {
                    text: "Opsblox Help",
                    icon: <HelpOutlineOutlined />,
                    external: false,
                    header: true,
                  },
                  ...catalogusHelp.map((catalogue) => ({
                    text: catalogue.name,
                    link: catalogue.isService ? catalogue.url : `catalogue/${catalogue.id}`,
                    external: catalogue.isExternal,
                  })),
                ]
              : []),
      
        // Other items if needed
      ],
      
    },

    {
      text: "Admin",
      icon: <AdminPanelSettingsOutlined />,
      currentStatusItems: [
        {
          text: "Config",
          icon: <RadarOutlined/>,
          link: null,
          external: false,
          header: true,
        },
        
        {
          text: "Blox",
          icon: <WidgetsOutlined />,
          link: "blox",
          external: false,
          homePage:true,
        },
        {
          text: "Utilities",
          icon: <FlagOutlined />,
          link: "utility",
          external: false,
        },
        {
          text: "KPIs",
          icon: <InsightsOutlined />,
          link: "kpi",
          external: false,
        },


        {
          text: "Job Processing",
          icon: <DirectionsRunOutlined />,
          link: "",
          external: false,
          header: true,
        },

        {
          text: "Scheduling",
          link: "schedule",
          external: false,
        },

        {
          text: "Jobs",
          link: "job",
          external: false,
        },

        {
          text: "User Stuff",
          icon: <PersonOutlined />,
          link: "",
          external: false,
          header: true,
        },
        {
          text: "Users",
          link: "user",
          external: false,
        },
        {
          text: "Sessions",
          link: "sessions",
          external: false,
        },
        {
          text: "User Roles",
          link: "roles",
          external: false,
        },
        {
          text: "User Preferences",
          link: "preference",
          external: false,
        },

        {
          text: "System",
          icon: <MenuOpenOutlined />,
          link: "",
          external: false,
          header: true,
        },

        {
          text: "Servics",
          icon: <LanOutlined />,
          link: "service",
          external: false,
        },
        {
          text: "Audit Log",

          link: "auditlog",
          external: false,
        },

        {
          text: "Copilot Conversations",
          link: "copilot",
          external: false,
        },
        {
          text: "Copilot Prompts",
          link: "copilot/prompt",
          external: false,
        },
        {
          text: "Search Prompts",
          link: "search_prompt",
          external: false,
        },

        {
          text: "Backups",
          link: "backup",
          external: false,
        },

        {
          text: "API",
          link: swagger_path,
          external: true,
        },



      ],
      configItems: [
        {
          text: "Config",
          icon: <SettingsOutlined/>,
          link: null,
          external: false,
          header: true,
        },

        {
          text: "State Sets",
          icon: <SettingsSuggestOutlined />,
          link: "state",
          external: false,
        },
        {
          text: "Dashboard Widgets",
          icon: <SettingsSuggestOutlined />,
          link: "state?filter=[type]=Dashboard Widget",
          external: false,
        },
        {
          text: "System Config",
          icon: <SettingsSuggestOutlined />,
          link: "config",
          external: false,
        },

        {
          text: "Workflow",
          icon: <AccountTreeOutlined />,
          external: false,
          header: true
        },

        {
          text: "Workflows",
          link: "workflow",
          external: false,
        },

        {
          text: "Contributions",
          link: "workflowcontribution",
          external: false,
        },

        {
          text: "Steps",
          link: "workflowstep",
          external: false,
        },


        

        {
          text: "Number Sequences",

          link: "sequence",
          external: false,
        },
      ],

      masterDataItems: [

        {
          text: "Catalogue",
          icon: <MenuBookOutlined />,
          external: false,
          header: true,
        },
        {
          text: "Catalogues",
          link: "catalogue_admin",
          external: false,
        },
        {
          text: "Catalogue Pages",
          link: "catalogue_page_admin",
          external: false,
        },

        {
          text: "Metta Data",
          icon: <AttachFileOutlined />,
          external: false,
          header: true,
        },
        {
          text: "Attachments",
          link: "attachment",
          external: false,
        },

      ],

    },
  ];

  return navAreas
}

export default GetMenuItems;