import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
//import { DateTimeField, DateTimePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import {
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItemText,
  MenuItem,
  FormControl,
  Checkbox,
  Select,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import FlexBetween from "./global/FlexBetween";
import { Box } from "@mui/system";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import moment from "moment-timezone";
import { getKpiFilterValues } from "slices/kpi";
import NiceDate from "./global/NiceDate";
import { getJobFilterValues, getJobLogFilterValues } from "slices/job";
import { getUtilityFilterValues } from "slices/utility";
import { getStateSetFilterValues } from "slices/state";
import { getCloudOrgStructureElementFilterValues } from "slices/cloudOrgStructure";
import { getOpportunityFilterValues } from "slices/opportunity";
import { getAuditLogFilterValues } from "slices/auditLog";
import { getPreferenceFilterValues } from "slices/preference";
import { getServiceFilterValues } from "slices/services";
import { getSequenceFilterValues } from "slices/sequence";
import {
  getCopilotConversation,
  getCopilotConversationFilterValues,
  getCopilotPromptFilterValues,
} from "slices/copilot";
import { getBackupFilterValues } from "slices/backup";
import { getAttachmentFilterValues } from "slices/attachment";

const DataGridFilterCreate = ({
  column,
  closer,
  saver,
  currentFilterValues,
  filters,
  serviceType,
  dimension,
  masterId,
  masterIdField,
  stateSetId,
  billingPeriod,
  props,
}) => {
  const dispatch = useDispatch();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250,
      },
    },
  };

  const search = {
    id: masterId,
    idField: masterIdField,
    field: column.field,
    filter: filters,
    dimension: dimension,
    stateSetId: stateSetId,
    billingPeriod: billingPeriod,
  };

  //remove any alls form the filter and the column we are looking for if its there

  //debugger

  if (search.filter) {
    // Split the string on '|'
    const parts = search.filter?.split("|");
    const thisFilter = `[${column.field}]`;

    console.log("This filter: ", thisFilter);

    // Process each part and filter out [when]=all
    const filteredParts = parts.filter((part) => {
      // Check if the part does not end with '=all'
      return !part.endsWith("=all") && !part.startsWith(thisFilter);
    });

    // Join the filtered parts back together with '|'
    search.filter = filteredParts.join("|");

    console.log("filter filter: ", search.filter);
  }

  let stateArea = "";
  let stateSubArea = "";

  switch (serviceType) {
    case "copilotConversation":
      stateArea = "copilot";
      stateSubArea = "conversation";

      break;

    case "copilotPrompt":
      stateArea = "copilot";
      stateSubArea = "prompt";

      break;

    case "preference":
      stateArea = "auth";
      stateSubArea = "user_preferences";

      break;

    default:
      stateArea = serviceType;
      stateSubArea = "";
      break;
  }

  const newValues = useSelector((state) => {
    if (stateSubArea !== "") {
      return state[stateArea]?.[stateSubArea]?.filter_values?.stringValues;
    } else {
      return state[stateArea]?.filter_values?.stringValues;
    }
  });

  const [filterMode, setFilterMode] = React.useState(() => {
    if (currentFilterValues.value === "all") {
      return "all";
    } else {
      if (currentFilterValues.operator === "<>") {
        return "range";
      }

      if (currentFilterValues.value === undefined) {
        return "specific";
      } else {
        if (column.type === "date") {
          return "specific";
        } else {
          return "select";
        }
      }
    }
  });

  const handleChange = (event) => {
    setFilterMode(event.target.value);
    if (event.target.value === "all") {
      setValue("all");
      setOperator("=");
    }
  };

  const [columnValues, setColumnValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  console.log("Column values: ", columnValues);

  useEffect(() => {
    console.log("Search for filter values ", search);

    //debugger;

    switch (serviceType) {
      case "kpi":
        dispatch(getKpiFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "kpiTargets":
        dispatch(getKpiFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "services":
        //debugger;

        dispatch(getServiceFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "sequence":
        //debugger;

        dispatch(getSequenceFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "copilotConversation":
        //debugger;

        dispatch(getCopilotConversationFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "copilotPrompt":
        //debugger;

        dispatch(getCopilotPromptFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "utility":
        //debugger;

        dispatch(getUtilityFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "cloudOrgStructure":
        //debugger;

        dispatch(getCloudOrgStructureElementFilterValues({ search })).then(
          () => {
            setIsLoading(false); // Mark loading as complete after data is fetched
          }
        );
        break;

      case "opportunity":
        //debugger;

        dispatch(getOpportunityFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "auditLog":
        //debugger;

        dispatch(getAuditLogFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "preference":
        //debugger;

        dispatch(getPreferenceFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "stateSet":
        dispatch(getStateSetFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "job":
        dispatch(getJobFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "joblog":
        dispatch(getJobLogFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "backup":
        dispatch(getBackupFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      case "attachment":
        dispatch(getAttachmentFilterValues({ search })).then(() => {
          setIsLoading(false); // Mark loading as complete after data is fetched
        });
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setColumnValues(newValues);
    }
  }, [isLoading]);

  const [valueList, setValueList] = useState(() => {
    const values = [
      // {
      //   value: "all",
      //   label: "All",
      // },
    ];

    return values;
  });

  useEffect(() => {
    //add the values to the list

    if (columnValues) {
      const newValues = columnValues?.map((value) => ({
        value: value,
        label: value,
      }));

      // Add the new values to the existing values
      setValueList((prevValueList) => [...prevValueList, ...newValues]);
    }
  }, [columnValues]);

  const [operator, setOperator] = useState(() => {
    //debugger;

    if (column.type === "boolean") {
      if (currentFilterValues.operator !== undefined) {
        return currentFilterValues.operator;
      } else {
        return true;
      }
    } else {
      if (currentFilterValues.operator !== undefined) {
        return currentFilterValues.operator;
      } else {
        return "*NN*";
      }
    }
  });

  const [listValue, setListValue] = React.useState(() => {
    //debugger

    if (column.type === "date") {
      if (
        currentFilterValues.value !== undefined &&
        currentFilterValues.value !== "all"
      ) {
        const valuesArray = currentFilterValues.value.split(",");
        return valuesArray;
      } else {
        return [];
      }
    } else {
      if (
        currentFilterValues.value !== undefined &&
        currentFilterValues.value !== "all"
      ) {
        const valuesArray = currentFilterValues.value.split(",");
        return valuesArray;
      } else {
        return [];
      }
    }
  });

  const [value, setValue] = React.useState(() => {
    //debugger;

    if (column.type === "date") {
      if (
        currentFilterValues.value !== undefined &&
        currentFilterValues.value !== "all"
      ) {
        return moment(currentFilterValues.value);
      } else {
        return moment.unix(moment.now() / 1000);
      }
    } else {
      if (currentFilterValues.value !== undefined) {
        return currentFilterValues.value;
      } else {
        return "";
      }
    }
  });

  const [valueFrom, setValueFrom] = React.useState(() => {
    //debugger;

    let values = currentFilterValues.value?.split(",");

    if (column.type === "date") {
      if (values?.length === 2) {
        return moment(values[0]);
      } else {
        return moment.unix(moment.now() / 1000);
      }
    } else {
      if (values?.length === 2) {
        return values[0];
      } else {
        return "";
      }
    }
  });

  const [valueTo, setValueTo] = React.useState(() => {
    //debugger;

    const values = currentFilterValues.value?.split(",");

    if (column.type === "date") {
      if (values?.length === 2) {
        return moment(values[1]);
      } else {
        return moment.unix(moment.now() / 1000);
      }
    } else {
      if (values?.length === 2) {
        return values[1];
      } else {
        return "";
      }
    }
  });

  const [property, setProperty] = React.useState(column.headerName);

  console.log("Filter Wanted: ", column);
  console.log("Passed values:", currentFilterValues);

  const setFilter = () => {
    let newFilter = {
      field: column.field,
      operator: operator,
      oldKey: currentFilterValues.key,
    };

    //update operator for range

    if (filterMode === "range") {
      newFilter.operator = "<>";

      let rangeValueFrom = "";
      let rangeValueTo = "";
      //it is so we need to check and form the 2 fields

      if (column.type === "date") {
        if (moment.isMoment(valueFrom)) {
          const utcTime = valueFrom.utc();
          rangeValueFrom = utcTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
        } else {
          //do an error
        }

        if (moment.isMoment(valueTo)) {
          const utcTime = valueTo.utc();
          rangeValueTo = utcTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
        } else {
          //do an error
        }

        newFilter.value = rangeValueFrom + "," + rangeValueTo;
      } else {
        newFilter.value = valueFrom + "," + valueTo;
      }
    } else {
      if (column.type === "date") {
        //check if its a rnage

        if (Array.isArray(value)) {
          const momentsArray = value.map((thisValue) => {
            const utcTime = moment(thisValue).utc();
            return utcTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
          });
          newFilter.value = momentsArray;
        } else if (moment.isMoment(value)) {
          debugger;
          const utcTime = value.utc();
          newFilter.value = utcTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
        } else {
          if (value === "all") {
            newFilter.value = value;
          }
        }
      } else if (value === "all") {
        newFilter.value = value;
      } else {
        newFilter.value = value;
      }
    }

    // if (column.type === "date" && value !== "all") {

    //   //if we have a moment object form the datapicker convert to UTC and save

    //   const momentsArray = value.map((thisValue) => {
    //     const utcTime = moment(thisValue).utc();
    //     return utcTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
    //   });
    //   newFilter.value = momentsArray;
    // } else {
    //   newFilter.value = value;
    // }

    newFilter.key = column.field + "_" + operator + "_" + newFilter.value;

    saver(newFilter);
    closer();
  };

  const boolOperators = [
    {
      value: "true",
      label: "is True",
    },
    {
      value: "false",
      label: "is False",
    },
  ];

  const stringOperators = [
    {
      value: "=",
      label: "Equals",
    },
    {
      value: "NN*",
      label: "Starts with",
    },
    {
      value: "*NN",
      label: "Ends with",
    },
    {
      value: "*NN*",
      label: "Contains",
    },
    {
      value: "!=",
      label: "Not equal to",
    },
    {
      value: "null",
      label: "Is null",
    },
    {
      value: "notnull",
      label: "Is not null",
    },
  ];

  const numberOperators = [
    {
      value: "=",
      label: "Equals",
    },
    {
      value: ">",
      label: "Grater than",
    },
    {
      value: ">=",
      label: "Grater than or equal to",
    },
    {
      value: "<",
      label: "Less than",
    },
    {
      value: "<=",
      label: "Less than or equal to",
    },
    {
      value: "!=",
      label: "Not Equal to",
    },
  ];

  const dateOperators = [
    {
      value: "=",
      label: "Equals",
    },
    {
      value: ">",
      label: "Grater than",
    },
    {
      value: ">=",
      label: "Grater than or equal to",
    },
    {
      value: "<",
      label: "Less than",
    },
    {
      value: "<=",
      label: "Less than or equal to",
    },
    {
      value: "!=",
      label: "Not Equal to",
    },
  ];

  let operateList = [];

  switch (column.type) {
    case "date":
      operateList = dateOperators;
      break;
    case "number":
      operateList = numberOperators;
      break;
    case "boolean":
      operateList = boolOperators;
      break;
    default:
      operateList = stringOperators;
  }

  console.log("List Vlaue: ", listValue);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="25rem"
      m="1rem"
      gap="1rem"
      sx={{ bgcolor: "background.paper" }}
    >
      <TextField
        label="Property"
        variant="outlined"
        inputProps={{ readOnly: true }}
        value={property}
        onChange={(event) => {
          setProperty(event.target.value);
        }}
        size="small"
      />

      <FormControl>
        <RadioGroup
          row
          aria-labelledby="filter_mode"
          value={filterMode}
          onChange={handleChange}
          name="radio-buttons-group"
        >
          <FormControlLabel value="all" control={<Radio />} label="All" />
          <FormControlLabel value="select" control={<Radio />} label="Select" />
          <FormControlLabel
            value="specific"
            control={<Radio />}
            label="Specific Value"
          />
          <FormControlLabel
            value="range"
            control={<Radio />}
            label="Range"
            disabled={column.type !== "date" && column.type !== "number"}
          />
        </RadioGroup>
      </FormControl>

      {filterMode === "range" &&
        (column.type === "date" ? (
          <>
            <DateTimePicker
              timezone="system"
              label="From"
              value={valueFrom}
              //onChange={(newValue) => {setValue(newValue.unix())}}
              onChange={setValueFrom}
              views={["year", "month", "day", "hours", "minutes", "seconds"]}
            />
            <DateTimePicker
              timezone="system"
              label="To"
              value={valueTo}
              //onChange={(newValue) => {setValue(newValue.unix())}}
              onChange={setValueTo}
              views={["year", "month", "day", "hours", "minutes", "seconds"]}
            />
          </>
        ) : (
          <>
            <TextField
              label="From"
              variant="outlined"
              value={valueFrom}
              onChange={(event) => {
                setValueFrom(event.target.value);
              }}
              size="small"
            ></TextField>

            <TextField
              label="To"
              variant="outlined"
              value={valueTo}
              onChange={(event) => {
                setValueTo(event.target.value);
              }}
              size="small"
            ></TextField>
          </>
        ))}

      {filterMode === "specific" && (
        <>
          <TextField
            select
            label="Operator"
            variant="outlined"
            value={operator}
            onChange={(event) => {
              setOperator(event.target.value);
            }}
            size="small"
          >
            {operateList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {column.type === "date" ? (
            <DateTimePicker
              timezone="system"
              label="Value test"
              value={value}
              //onChange={(newValue) => {setValue(newValue.unix())}}
              onChange={setValue}
              views={["year", "month", "day", "hours", "minutes", "seconds"]}
            />
          ) : (
            <TextField
              label="Value"
              variant="outlined"
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              size="small"
            ></TextField>
          )}
        </>
      )}

      {filterMode === "select" && (
        <FormControl>
          <InputLabel id="select_value_label">Value List</InputLabel>
          <Select
            input={<OutlinedInput label="Value List" />}
            labelId="select_value_label"
            multiple
            MenuProps={MenuProps}
            value={listValue}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return "None"; // Display "None" if nothing is selected
              } else if (selected.length === 1) {
                if (column.type === "date") {
                  return `${selected.length} items selected`;
                } else {
                  return selected[0]; // Display the selected value if only one is selected
                }
              } else {
                return `${selected.length} items selected`; // Display the count if multiple items are selected
              }
            }}
            onChange={(event) => {
              setListValue(event.target.value);
              if (column.type === "date") {
                console.log("date:", event.target.value);
                // const valuesArray = event.target.value.split(",");
                const momentsArray = event.target.value.map((value) =>
                  moment(value)
                );
                // debugger;

                setValue(event.target.value);
              } else {
                setValue(event.target.value);
              }
            }}
            size="small"
          >
            {valueList?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={listValue.indexOf(option.value) > -1} />
                {column.type === "date" ? (
                  <ListItemText primary={<NiceDate>{option.label}</NiceDate>} />
                ) : (
                  <ListItemText primary={option.label} />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Box display="flex" gap="1rem" justifyContent="flex-end">
        <Button onClick={closer}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={setFilter}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default DataGridFilterCreate;
