import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  TextField,
  Stack,
  MenuItem,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { clearMessage, setMessage } from "slices/message";
import Header from "components/global/Header";
import { createCataloguePage, getCataloguePageTypes } from "slices/catalogue";
import { getServiceDimensionValues } from "slices/dimensions";

const CataloguePageAdd = ({
  closerFunc,
  catalogueId,
  page,
  sequence,
  pagesFullDetail,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const dispatch = useDispatch();

  //const catalogues = useSelector((state) => state.catalogue.catalogue.data.list);
  //const pages = useSelector((state) => state.catalogue.page.data.list);

  const [selectedCatalogue, setSelectedCatalogue] = useState(
    catalogueId ? catalogueId : null
  );
  const [selectedParentPage, setSelectedParentPage] = useState(
    page ? page : null
  );
  const [selectedPageType, setSelectedPageType] = useState("");

  //debugger
  const parentPageDetail = pagesFullDetail?.find(
    (pageDetail) => pageDetail.id === page
  );

  const [selectedSavingCategory, setSelectedSavingCategory] = useState(
    parentPageDetail ? parentPageDetail.savingCategory : ""
  );
  const [selectedSavingGroups, setSelectedSavingGroups] = useState(
    null
  );

  const catalogue = useSelector((state) =>
    state.catalogue.catalogue.data.list?.find(
      (element) => element.id === catalogueId
    )
  );

  const pageTypes = useSelector((state) => state.catalogue.pageTypes);

  const [availablePageTypes, setAvailablePageTypes] = useState(pageTypes);

  const catalogues = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "kernel" &&
          dimension.implementor === "catalogue" &&
          dimension.field === "id"
      )?.values
  );

  const pages = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "kernel" &&
          dimension.implementor === "cataloguePage" &&
          dimension.field === "id"
      )?.values
  );

  const sericeListStateSet =
    catalogue?.cloud === "AZURE" ? "AZURE_SERVICE" : null;

  const availableServices = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "stateSet" &&
          dimension.implementor === sericeListStateSet &&
          dimension.field === "id"
      )?.values
  );

  const availableServiceCategories = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "stateSet" &&
          dimension.implementor === "SERVICE_CATEGORY" &&
          dimension.field === "id"
      )?.values
  );

  const availableSavingsCategories = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "stateSet" &&
          dimension.implementor === "SAVING_CATEGORY" &&
          dimension.field === "id"
      )?.values
  );

  const availableSavingsGroups = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "stateSet" &&
          dimension.implementor === "SAVING_MODEL" &&
          dimension.field === "savingGroup"
      )?.values
  );

  const availableSavingsModels = useSelector(
    (state) =>
      state.dimensions?.dimensions?.find(
        (dimension) =>
          dimension.type === "stateSet" &&
          dimension.implementor === "SAVING_MODEL" &&
          dimension.field === "_id"
      )?.values
  );

  useEffect(() => {
    //on mount get some defaults

    if (!catalogues) {
      let search = {
        type: "kernel",
        implementor: "catalogue",
        field: "id",
        lookUpLabel: "<type> - <name>",
        sort: "sequence",
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    if (!pageTypes || pageTypes.length === 0) {
      dispatch(getCataloguePageTypes());
    }
  }, []);

  useEffect(() => {
    let search = {
      type: "kernel",
      implementor: "cataloguePage",
      field: "id",
      lookUpLabel: "<name> - <pageType>",
      sort: "name",
      filter: "[catalogueId]=" + selectedCatalogue,
    };

    //debugger
    dispatch(getServiceDimensionValues({ search }));
  }, [selectedCatalogue, dispatch]);

  useEffect(() => {
    // Set the available page type options based on the page type selected

    const parentPage = pagesFullDetail?.find(
      (page) => page.id === selectedParentPage
    );

    const filteredPageTypes = pageTypes?.filter((pageType) => {
      if (!parentPage) {
        // If there is no parent page, include all types with parentType 'ALL' or 'TOP'
        return pageType.parentType === "ALL" || pageType.parentType === "TOP";
      }

      // Split the parentType by commas to handle multiple parent types
      const parentTypes = pageType.parentType
        .split(",")
        .map((type) => type.trim());

      // Check if any of the parent types match the parentPage.pageType or if parentType is 'ALL'
      return (
        parentTypes.includes(parentPage.pageType) || parentTypes.includes("ALL")
      );
    });

    setAvailablePageTypes(filteredPageTypes);

    //if the page type is a service category group we get the services based on the parent category

    if (parentPage?.pageType === "Service Category") {
      //debugger;
      let search = {
        type: "stateSet",
        implementor: sericeListStateSet,
        field: "id",
        //lookUpLabel: "<name>",
        filter: "[serviceCategory]=" + parentPage.serviceCategory,
        sort: "-id",
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    //debugger

    let search = {
      type: "stateSet",
      implementor: "SAVING_MODEL",
      field: "savingGroup",
      //lookUpLabel: "<name>",
      filter: "[savingCategory]=" + selectedSavingCategory,
      sort: "-savingGroup",
    };

    dispatch(getServiceDimensionValues({ search }));

    search = {
      type: "stateSet",
      implementor: "SAVING_MODEL",
      field: "_id",
      //lookUpLabel: "<name>",
      filter: "[savingGroup]=" + selectedSavingGroups,
      sort: "-_id",
    };

    dispatch(getServiceDimensionValues({ search }));
  }, [
    selectedParentPage,
    selectedPageType,
    pageTypes,
    selectedSavingCategory,
    selectedSavingGroups,
  ]);

  useEffect(() => {
    //on mount get some defaults

    if (!availableServiceCategories) {
      let search = {
        type: "stateSet",
        implementor: "SERVICE_CATEGORY",
        field: "id",
        //lookUpLabel: lookUpLabel,
      };

      dispatch(getServiceDimensionValues({ search }));
    }

    if (!availableSavingsCategories) {
      let search = {
        type: "stateSet",
        implementor: "SAVING_CATEGORY",
        field: "id",
        //lookUpLabel: lookUpLabel,
      };

      dispatch(getServiceDimensionValues({ search }));
    }
  }, []);

  const initialValues = {};

  initialValues.id = "";
  initialValues.name = "";

  initialValues.catalogueId = catalogueId ? catalogueId : "";

  initialValues.hideContent = false;
  initialValues.parentPage = selectedParentPage ? selectedParentPage : "";
  initialValues.sequence = sequence ? sequence : 1;
  initialValues.pageType = "Standard";
  initialValues.service = "";
  initialValues.serviceCategory = "";
  initialValues.savingCategory = "";
  initialValues.savingGroups = [];
  initialValues.savingModels = [];
  initialValues.productGroups = [];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    catalogueId: Yup.string().required("This field is required"),
    sequence: Yup.string().required("This field is required"),
  });

  console.log("Savings Groups:", selectedSavingGroups);

  return (
    <Box m="20px">
      <Header title={"New Page"} />

      <Formik
        onSubmit={(values, actions) => {
          const cataloguePage = { ...values };

          dispatch(clearMessage());

          dispatch(createCataloguePage({ cataloguePage }))
            .unwrap()
            .then((data) => {
              //debugger;

              dispatch(setMessage("Page Added", "success"));
              debugger;
              closerFunc(data.data.data.id);
            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        //enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 1" },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                select
                variant="outlined"
                type="string"
                label="Catalogue"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setSelectedCatalogue(e.target.value);
                }}
                value={values.catalogueId}
                name="catalogueId"
                error={!!touched.catalogueId && !!errors.catalogueId}
                helperText={touched.catalogueId && errors.catalogueId}
                sx={{ gridColumn: "span 1" }}
                disabled={catalogue ? true : false}
              >
                {catalogues?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                select
                variant="outlined"
                type="string"
                label="Parent Page"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setSelectedParentPage(e.target.value);
                }}
                value={values.parentPage}
                name="parentPage"
                error={!!touched.parentPage && !!errors.parentPage}
                helperText={touched.parentPage && errors.parentPage}
                sx={{ gridColumn: "span 1" }}
                disabled={values.catalogueId === "" || page}
              >
                <MenuItem key={"none"} value={""}>
                  {"None"}
                </MenuItem>
                {pages?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                select
                variant="outlined"
                type="string"
                label="Page Type"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  setSelectedPageType(e.target.value);
                }}
                value={values.pageType}
                name="pageType"
                error={!!touched.pageType && !!errors.pageType}
                helperText={touched.pageType && errors.pageType}
                sx={{ gridColumn: "span 1" }}
              >
                {availablePageTypes?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {selectedPageType === "Service" && (
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  type="string"
                  label="Service"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    //setSelectedCatalogue(e.target.value);
                  }}
                  value={values.service}
                  name="service"
                  error={!!touched.service && !!errors.service}
                  helperText={touched.service && errors.service}
                  sx={{ gridColumn: "span 1" }}
                  //disabled={selectedPageType !== "Pattern Service" && true}
                >
                  {availableServices?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {(selectedPageType === "Saving Category" ||
                selectedPageType === "Saving Category - General") && (
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  type="string"
                  label="Saving Category"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setSelectedSavingCategory(e.target.value);
                  }}
                  value={values.savingCategory}
                  name="savingCategory"
                  error={!!touched.savingCategory && !!errors.savingCategory}
                  helperText={touched.savingCategory && errors.savingCategory}
                  sx={{ gridColumn: "span 1" }}
                  //disabled={selectedPageType !== "Pattern Service" && true}
                >
                  {availableSavingsCategories?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {selectedPageType === "Pattern - Saving Category" && (
                <>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    type="string"
                    label="Saving Groups"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const value = e.target.value;
                      handleChange(e);
                      setFieldValue(
                        "savingGroups",
                        typeof value === "string" ? value.split(",") : value
                      );
                      setSelectedSavingGroups(
                        typeof value === "string" ? value.split(",") : value
                      );
                    }}
                    value={values.savingGroups || []} // Ensure savingGroups is an array
                    name="savingGroups"
                    error={!!touched.savingGroups && !!errors.savingGroups}
                    helperText={touched.savingGroups && errors.savingGroups}
                    sx={{ gridColumn: "span 1" }}
                    SelectProps={{
                      multiple: true, // Enable multi-select
                      renderValue: (selected) => selected.join(", "), // Render selected values as a comma-separated string
                    }}
                  >
                    {availableSavingsGroups?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {/* Checkbox for visual multi-select indication */}
                        <Checkbox
                          checked={
                            values.savingGroups.indexOf(option.value) > -1
                          }
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </TextField>

                  {selectedSavingGroups && selectedSavingGroups.length > 0 && (
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      type="string"
                      label="Saving Models"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleChange(e);
                        setFieldValue(
                          "savingModels",
                          typeof value === "string" ? value.split(",") : value
                        );
                      }}
                      value={values.savingModels || []} // Ensure savingModels is an array
                      name="savingModels"
                      error={!!touched.savingModels && !!errors.savingModels}
                      helperText={touched.savingModels && errors.savingModels}
                      sx={{ gridColumn: "span 1" }}
                      SelectProps={{
                        multiple: true, // Enable multi-select
                        renderValue: (selected) => selected.join(", "), // Render selected values as a comma-separated string
                      }}
                    >
                      {availableSavingsModels?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {/* Checkbox for visual multi-select indication */}
                          <Checkbox
                            checked={
                              values.savingModels.indexOf(option.value) > -1
                            }
                          />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </>
              )}

              {selectedPageType === "Service Category" && (
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  type="string"
                  label="Service Category"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    //setSelectedCatalogue(e.target.value);
                  }}
                  value={values.serviceCategory}
                  name="serviceCategory"
                  error={!!touched.serviceCategory && !!errors.serviceCategory}
                  helperText={touched.serviceCategory && errors.serviceCategory}
                  sx={{ gridColumn: "span 1" }}
                  //disabled={selectedPageType !== "Service Category Definition" && true}
                >
                  {availableServiceCategories?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.hideContent}
                    name="hideContent"
                  />
                }
                label="Make menu only page"
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="2rem" mb="2rem">
              <Stack direction="row" spacing={2} mb="2rem">
                <Button
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={() => closerFunc()}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Add Page
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default CataloguePageAdd;
