import { Box, Typography, useMediaQuery } from "@mui/material";

import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "slices/message";
import { useSearchParams } from "react-router-dom";

import KeyVal from "components/global/KeyVal";
import {
  getStateDataId,
  getStateSet,
  getStateSetData,
  getStateSetServiceUi,
  getStateUi,
  updateStateSet,
} from "slices/state";

import StateSetDataDisplayEdit from "components/state/StateSetDataDisplayEdit";
import {
  getUtility,
  getUtilityData,
  getUtilityServiceUi,
  getUtilityUi,
} from "slices/utility";

import { getKpi, getKpiData, getKpiUi } from "slices/kpi";
import { getService, getServiceUi } from "slices/services";
import {
  getOpportunity,
  getOpportunityUi,
  updateOpportunity,
} from "slices/opportunity";
import {
  getCloudOrgStructureElement,
  getCloudOrgStructureUi,
  updateCloudOrgStructureElement,
} from "slices/cloudOrgStructure";
import { getAuditLog, getAuditLogUi } from "slices/auditLog";
import ObjectDetailsGroup from "./ObjectDetailsGroup";
import { getPreference, getPreferenceUi } from "slices/auth";
import { getSequence, getSequenceUi } from "slices/sequence";
import {
  getCopilotConversation,
  getCopilotConversationUi,
  getCopilotPrompt,
  getCopilotPromptUi,
} from "slices/copilot";
import {
  getCatalogue,
  getCataloguePage,
  getCataloguePageUi,
  getCatalogueUi,
  updateCatalogue,
  updateCataloguePage,
} from "slices/catalogue";
import { getSearchPrompt, getSearchPromptUi } from "slices/search";
import { getConfig, getConfigUi, updateConfig } from "slices/config";
import { getBackup, getBackupUi } from "slices/backup";
import { getAttachment, getAttachmentUi } from "slices/attachment";

const ObjectDetails = ({
  serviceType,
  id,
  rowId,
  refreshPoint,
  titleSetter,
  hideId,
}) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const showHiddenFields = useSelector((state) => {
    const preference = state.auth.user.preferences?.find(
      (preference) => preference.name === "showHidden"
    )?.preferenceValue;

    return preference ? (preference === "true" ? true : false) : false;
  });

  const showTechnicalNames = useSelector((state) => {
    const preference = state.auth.user.preferences?.find(
      (preference) => preference.name === "showTechnicalNames"
    )?.preferenceValue;

    return preference ? (preference === "true" ? true : false) : false;
  });

  let stateArea = "";
  let stateSubArea = "";
  let stateRowArea = "";
  let stateUiArea = "";
  let uiGetter = null;
  let objectGetter = null;
  let rowGetter = null;
  let updater = null;

  const search = {};

  switch (serviceType) {
    case "kpi":
      stateArea = serviceType;
      stateRowArea = "kpi_data";
      stateUiArea = "kpiUi";

      search.id = id;
      search.filter = `[id]=${rowId}`;
      search.dimension = searchParams.get("dimension");

      //debugger;

      uiGetter = getKpiUi;
      objectGetter = getKpi;
      rowGetter = getKpiData;

      break;

    case "utility":
      if (rowId) {
        stateArea = serviceType;
        stateRowArea = "utility_data";
        stateUiArea = "utilityUi";

        search.id = id;
        search.filter = `[id]=${rowId}`;
        search.billingPeriod = searchParams.get("billingPeriod");

        //debugger;

        uiGetter = getUtilityUi;
        objectGetter = getUtility;
        rowGetter = getUtilityData;
      } else {
        stateArea = serviceType;
        stateUiArea = "Ui";

        search.id = id;
        uiGetter = getUtilityServiceUi;
        objectGetter = getUtility;
        rowGetter = null;
      }

      break;

    case "stateSet":
      if (rowId) {
        stateArea = serviceType;
        stateRowArea = "rows";
        stateUiArea = "stateUi";

        search.state_set_id = id;
        search.id = rowId;

        uiGetter = getStateUi;
        objectGetter = getStateSet;
        rowGetter = getStateSetData;
      } else {
        stateArea = serviceType;
        stateUiArea = "Ui";

        search.state_set_id = id;
        uiGetter = getStateSetServiceUi;
        objectGetter = getStateSet;
        rowGetter = null;
      }
      break;

    case "opportunity":
      stateArea = serviceType;
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getOpportunityUi;
      objectGetter = getOpportunity;
      rowGetter = getOpportunity;
      updater = updateOpportunity;

      break;

    case "cloudOrgStructure":
      stateArea = serviceType;
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getCloudOrgStructureUi;
      objectGetter = getCloudOrgStructureElement;
      rowGetter = getCloudOrgStructureElement;
      updater = updateCloudOrgStructureElement;
      break;

    case "auditLog":
      stateArea = serviceType;
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getAuditLogUi;
      objectGetter = getAuditLog;
      rowGetter = getAuditLog;

      break;

    case "services":
      stateArea = serviceType;
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getServiceUi;
      objectGetter = getService;
      rowGetter = getService;

      break;

    case "copilotConversation":
      stateArea = "copilot";
      stateSubArea = "conversation";
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getCopilotConversationUi;
      objectGetter = getCopilotConversation;
      rowGetter = getCopilotConversation;

      break;

    case "copilotPrompt":
      stateArea = "copilot";
      stateSubArea = "prompt";
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getCopilotPromptUi;
      objectGetter = getCopilotPrompt;
      rowGetter = getCopilotPrompt;

      break;

    case "searchPrompt":
      stateArea = "search";
      stateSubArea = "prompt";
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getSearchPromptUi;
      objectGetter = getSearchPrompt;
      rowGetter = getSearchPrompt;

      break;

    case "catalogue":
      stateArea = "catalogue";
      stateSubArea = "catalogue";
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getCatalogueUi;
      objectGetter = getCatalogue;
      rowGetter = getCatalogue;

      break;

    case "cataloguePage":
      stateArea = "catalogue";
      stateSubArea = "page";
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getCataloguePageUi;
      objectGetter = getCataloguePage;
      rowGetter = getCataloguePage;

      break;

    case "preference":
      stateArea = "auth";
      stateSubArea = "user_preferences";
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getPreferenceUi;
      objectGetter = getPreference;
      rowGetter = getPreference;

      break;

    case "config":
      stateArea = "config";
      stateUiArea = "Ui";

      search.id = "CONFIG";

      uiGetter = getConfigUi;
      objectGetter = getConfig;

      break;

    case "sequence":
      stateArea = "sequence";
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getSequenceUi;
      objectGetter = getSequence;
      rowGetter = getSequence;

      break;

    case "backup":
      stateArea = "backup";
      stateUiArea = "Ui";

      search.id = id;
      uiGetter = getBackupUi;
      objectGetter = getBackup;
      rowGetter = getBackup;

      break;

      case "attachment":
        stateArea = "attachment";
        stateUiArea = "Ui";
  
        search.id = id;
        uiGetter = getAttachmentUi;
        objectGetter = getAttachment;
        rowGetter = getAttachment;
  
        break;


    case "blox":
      stateArea = serviceType;
      stateRowArea = "blox_data";
      stateUiArea = "bloxUi";
      break;

    default:
      break;
  }

  const handelUpdate = (field, newVal) => {
    switch (serviceType) {
      case "stateSet":
        const stateSet = {
          id: id,
          [field]: newVal,
        };

        dispatch(updateStateSet({ stateSet }));
        break;

      case "opportunity":
        const opportunity = {
          id: id,
          [field]: newVal,
        };

        dispatch(updateOpportunity({ opportunity }));
        break;

      case "config":
        const config = {
          [field]: newVal,
        };

        dispatch(updateConfig({ config }));
        break;

      case "catalogue":
        const catalogue = {
          id: id,
          [field]: newVal,
        };

        dispatch(updateCatalogue({ catalogue }));
        break;

        // case "attachment":
        // const attachment = {
        //   id: id,
        //   [field]: newVal,
        // };

        // dispatch(updateat({ attachment }));
        // break;

      case "cataloguePage":
        const cataloguePage = {
          id: id,
          [field]: newVal,
        };

        dispatch(updateCataloguePage({ cataloguePage }));
        break;

      case "cloudOrgStructure":
        const CloudOrgStructureElement = {
          id: id,
          [field]: newVal,
        };

        dispatch(updateCloudOrgStructureElement({ CloudOrgStructureElement }));
        break;

      default:
        const message = {
          message: "Update not implemented",
          type: "error",
          longText: "",
          helpLink: "",
        };
        dispatch(setMessage(message));
    }
  };

  const rowValues = useSelector((state) => {
    //special check for config type
    if (serviceType === "config") {
      return state[stateArea]?.values;
    }

    if (rowId === undefined || rowId == null) {
      //debugger
      if (stateSubArea !== "") {
        return state[stateArea]?.[stateSubArea]?.data?.list.find(
          (item) => item.id === id
        );
      } else {
        return state[stateArea]?.data.list.find((item) => item.id === id);
      }
    } else {
      return state[stateArea]?.[stateRowArea]?.list.find(
        (item) => item.id === rowId
      );
    }
  });

  //console.log("row values: " , rowValues)
  //debugger;

  const stateSetValues = useSelector((state) => state.stateSet.values);

  const objectData = useSelector((state) => {
    //special check for config type
    if (serviceType === "config") {
      return state[stateArea]?.values;
    }

    let list = [];
    if (stateSubArea !== "") {
      list = state[stateArea]?.[stateSubArea]?.data?.list;
    } else {
      list = state[stateArea]?.data?.list;
    }

    //debugger
    return list ? list.find((item) => item.id === id) : null;
  });

  const rowUI = useSelector((state) => {
    if (stateSubArea !== "") {
      return state[stateArea]?.[stateSubArea]?.[stateUiArea];
    } else {
      return state[stateArea]?.[stateUiArea];
    }
  });

  const groups = rowUI?.groups;

  const [isLoading, setIsLoading] = useState(true);

  // console.log("single vals: ", stateSetValues);
  // console.log("row vals: ", rowValues);
  // console.log("row ui: ", rowUI);
  // console.log("object data: ", objectData);
  // console.log("first load: ", isFirstLoad);

  useEffect(() => {
    //search.id = rowId;
    //debugger;

    if (isFirstLoad) {
      //debugger
      // if (!objectData) {
      //   dispatch(objectGetter({ id }));
      // }

      dispatch(objectGetter({ id }));

      setIsFirstLoad(false);
    } else {
      dispatch(objectGetter({ id }));
    }
  }, [id, rowId, refreshPoint]);

  useEffect(() => {
    //search.id = rowId;
    //debugger;

    dispatch(uiGetter({ id }));

    if (objectData) {
      //debugger
      switch (serviceType) {
        case "stateSet":
          if (!objectData.tableMode) {
            dispatch(getStateDataId({ id }));
          } else {
            if (rowGetter) {
              dispatch(rowGetter({ search }));
            }
          }
          break;
        case "cloudOrgStructure":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "config":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "services":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "opportunity":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "auditLog":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "preference":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "sequence":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "backup":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "attachment":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "copilotConversation":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "copilotPrompt":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "searchPrompt":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        case "catalogue":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;
        case "cataloguePage":
          //dispatch(rowGetter({ id }));
          //dont dispatch as we

          break;

        default:
          if (rowGetter) {
            dispatch(rowGetter({ search }));
          }
          break;
      }

      if (titleSetter) {
        titleSetter(
          serviceType === "config"
            ? "Configguration Settings"
            : objectData?.name
        );
      }
    }
  }, [objectData, refreshPoint, rowId]);

  // console.log("Service type: " , serviceType)
  // console.log("rows " , rowValues)

  console.log(
    "Obtect Detail: ",
    isFirstLoad,
    " rowValues: ",
    rowValues,
    " object data: ",
    objectData,
    " rowUi: ",
    rowUI
  );

  //console.log("row id :", rowId);

  //debugger

  return (
    <Box>
      {!isFirstLoad &&
      ((rowValues && Object.keys(rowValues).length > 0) ||
        (stateSetValues && Object.keys(stateSetValues).length > 0)) &&
      objectData &&
      rowUI &&
      Object.keys(rowUI).length > 0 ? (
        serviceType === "stateSet" ? (
          rowId === null ? (
            <ObjectDetailsGroup
              key={"group_Screen"}
              groups={groups}
              parentName={"Screen"} //fixed value
              row={rowValues}
              rowUI={rowUI}
              showHidden={showHiddenFields}
              showTechnical={showTechnicalNames}
              hideId={hideId}
              serviceType={serviceType}
              updater={handelUpdate}
            />
          ) : (
            <StateSetDataDisplayEdit
              stateSet={objectData}
              set_values={objectData.tableMode ? rowValues : stateSetValues}
              schema={rowUI}
              showHidden={showHiddenFields}
              showTechnicalName={showTechnicalNames}
            />
          )
        ) : (
          <Box display="flex" flexDirection="column" gap=".6rem">
            {groups?.length > 0 ? (
              <ObjectDetailsGroup
                key={"group_Screen"}
                groups={groups}
                parentName={"Screen"} //fixed value
                row={rowValues}
                rowUI={rowUI}
                showHidden={showHiddenFields}
                showTechnical={showTechnicalNames}
                hideId={hideId}
                serviceType={serviceType}
                updater={handelUpdate}
              />
            ) : (
              // JSX elements for the case when rowUI?.groups.length <= 0
              rowUI?.columns.map((item) => {
                if (item.uiHide !== true || showHiddenFields) {
                  if (item.field === "id" && hideId === true) {
                    return null;
                  }
                  return (
                    <KeyVal
                      key={item.field}
                      label={item.headerName}
                      value={
                        rowValues[item.field] !== undefined
                          ? rowValues[item.field]
                          : "Not Set"
                      }
                      tip={item.helperText}
                      fieldType={item.type}
                      field={item.field}
                      flexDirection="column"
                      canEdit={item.valueMode === "System" ? false : true}
                      uiRenderType={
                        rowValues[item.field] !== undefined
                          ? item.renderCell
                          : ""
                      }
                      multiLine={item.multiLine}
                      rows={item.rows}
                      showTechnicalName={showTechnicalNames}
                      currencyCode={
                        rowValues[item.currencyField]
                        // rowValues["currencyCode"] !== undefined &&
                        // rowValues["currencyCode"]
                      }
                    />
                  );
                }
                return null; // Ensure you always return a value from map function
              })
            )}
          </Box>
        )
      ) : (
        <Typography>Loading</Typography>
      )}
    </Box>
  );
};

export default ObjectDetails;
