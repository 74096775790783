import { Box, Typography, Button, Divider } from "@mui/material";

import NiceDate from "components/global/NiceDate";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Header from "components/global/Header";

import useInterval from "common/useInterval";
import PropTypes from "prop-types";

import FlexBetween from "components/global/FlexBetween";

import PageBlock from "components/global/PageBlock";
import SimpleBreadcrumbs from "components/global/SimpleBreadcrumbs";
import {
  CancelOutlined,
  ErrorOutlineOutlined,
  LightbulbOutlined,
  RefreshOutlined,
  SaveOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";

import TabPanel from "components/global/TabPanel";

import { now } from "moment";
import TablePage from "components/global/TablePage";
import NiceUser from "components/global/NiceUser";

import ObjectDetails from "components/global/ObjectDetails";
import TabsVertical from "components/global/TabsVerticle";
import TabVertical from "components/global/TabVertical";
import { listAuditLog } from "slices/auditLog";
import {
  getCataloguePage,
  updateCataloguePage,
} from "slices/catalogue";


import CataloguePageEditor from "components/catalogue/CataloguePageEditor";
import {  getAttachmentByUser, getAttachmentUsedBys } from "slices/attachment";

const AdminCataloguePage = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);


  const page = useSelector((state) =>
    state.catalogue.page.data.list?.find((element) => element.id === id)
  );

  const [markdown, setMarkdown] = useState(page?.content);

  const auditLogRows = useSelector((state) => state.auditLog.data);

  const attachments = useSelector((state) => state.attachment.data);

  const [tabValue, setTabValue] = React.useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const [pageChanged, setPageChanged] = useState(false)

  const setTabValueAndUpdateQuery = (newValue) => {
    // Update the state
    setTabValue(newValue);

    // Update the query string
    const queryParams = new URLSearchParams(searchParams.toString());
    queryParams.set("tab", newValue);
    setSearchParams(queryParams.toString(), { replace: true });

    //console.log("setting tab:", queryParams.toString());
  };

  const handleTabChange = (event, newValue) => {
    setTabValueAndUpdateQuery(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [refreshPoint, setRefreshPoint] = useState(now());

  const handelRefresh = () => {
    setRefreshPoint(now());
  };

  const handelDiscardChanges = () => {
    setMarkdown(page.content);
    setPageChanged(false)
  };

  const handelSaveContent = () => {
    const cataloguePage = {
      id: page.id,
      content: markdown,
    };

    dispatch(updateCataloguePage({ cataloguePage }));

    setPageChanged(false)
  };

  useInterval(() => {}, 1000);

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(getCataloguePage({ id }));
      setIsFirstLoad(false);

      if (searchParams.get("tab")) {
        setTabValue(parseInt(searchParams.get("tab"), 10));
      } else {
        setTabValueAndUpdateQuery(tabValue);
      }
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getCataloguePage({ id }));
  }, [refreshPoint, dispatch, id]);

  useEffect(() => {
    setMarkdown(page?.content);
  }, [page]);

  const logColumns = [
    {
      field: "when",
      headerName: "Last Chnaged",
      flex: 1,
      renderCell: ({ row: { when } }) => {
        return <NiceDate>{when}</NiceDate>;
      },
    },
    {
      field: "userName",
      headerName: "User",
      flex: 1,
      renderCell: ({ row: { userName } }) => {
        return <NiceUser>{userName}</NiceUser>;
      },
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 1,
    },
    {
      field: "fieldName",
      headerName: "Field",
      flex: 1,
    },
    {
      field: "fieldUpdate",
      headerName: "Change",
      flex: 1,
    },
    {
      field: "ipAddress",
      headerName: "From",
      flex: 1,
    },
  ];

  const columnsVisability = {};

  const columnsVisabilityAttachments = {};

  const attahcmentColumns = [
    
    
    {
      field: "name",
      headerName: "Attachment Name",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
    },
    {
      field: "fileExt",
      headerName: "File Type",
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      renderCell: ({ row: { createdBy } }) => {
        return <NiceUser>{createdBy}</NiceUser>;
      },
    },
    {
      field: "modifiedAt",
      headerName: "Last Chnaged",
      flex: 1,
      renderCell: ({ row: { modifiedAt } }) => {
        return <NiceDate>{modifiedAt}</NiceDate>;
      },
    },
  ];

  return (
    <PageBlock>
      <SimpleBreadcrumbs lastOverride={page?.name} />

      <FlexBetween>
        <Header title={page?.name} subtitle={page?.id} />
        <FlexBetween gap=".5rem">
          {tabValue !== 1 && (
            <Button variant="outlined" color="primary" onClick={handelRefresh}>
              <RefreshOutlined />
            </Button>
          )}

          {tabValue === 1 && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handelSaveContent}
                startIcon={<SaveOutlined />}
                disabled={!pageChanged}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                onClick={handelDiscardChanges}
                startIcon={<CancelOutlined />}
                disabled={!pageChanged}
              >
                Discard Changes
              </Button>
            </>
          )}
        </FlexBetween>
      </FlexBetween>

      <Box
        display={"flex"}
        width={"100%"}
        minHeight={"75vh"}
        gap={".3rem"}
        mt={"20px"}
        sx={{ flexGrow: 1 }}
      >
        <Box
          gap="1rem"
          sx={{
            //borderBottom: 1,
            borderTop: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",

            flexGrow: 1,
          }}
        >
          <TabsVertical
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Service Tabs"
          >
            <TabVertical label={"Details"} {...a11yProps(0)} />
            <TabVertical
              label={"Content"}
              {...a11yProps(1)}
              disabled={page?.hideContent}
            />
            <TabVertical
              label={`Attachments (${attachments?.total})`}
              {...a11yProps(2)}
            />

            <TabVertical
              label={`Log (${auditLogRows?.total})`}
              {...a11yProps(3)}
            />
          </TabsVertical>

          <TabPanel value={tabValue} index={0}>
            {id !== undefined ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                gap="1rem"
              >
                <ObjectDetails
                  serviceType={"cataloguePage"}
                  id={id}
                  rowId={null}
                  refreshPoint={refreshPoint}
                  titleSetter={null}
                />
              </Box>
            ) : (
              <Typography>Nothing to load...</Typography>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            {id !== undefined && page ? (
              

              <CataloguePageEditor
                page={page}
                handleSetPageUpdated={setPageChanged}
                 handleUpdateContent={setMarkdown}
              />


            ) : (
              <Typography>Nothing to load...</Typography>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            {attachments ? (
              <TablePage
                masterId={id}
                masterIdField="objectId"
                dataRows={attachments}
                dataColumns={attahcmentColumns}
                dataGetter={getAttachmentByUser}
                defaultColumnsVisability={columnsVisabilityAttachments}
                defaultSort={"-name"}
                refreshPoint={refreshPoint}
                serviceType={"attachments"}
                //initialFilter={"[serviceType]=cataloguePage|[implementorId]=cataloguePage|[objectId]=" + id}
              />
            ) : (
              <Typography>Empty Log...</Typography>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            {auditLogRows ? (
              <TablePage
                masterId={id}
                masterIdField="objectId"
                dataRows={auditLogRows}
                dataColumns={logColumns}
                dataGetter={listAuditLog}
                defaultColumnsVisability={columnsVisability}
                defaultSort={"-when"}
                refreshPoint={refreshPoint}
                serviceType={"auditLog"}
                initialFilter={"[operation]=Create,Update"}
              />
            ) : (
              <Typography>Empty Log...</Typography>
            )}
          </TabPanel>
        </Box>
      </Box>
    </PageBlock>
  );
};

export default AdminCataloguePage;
